import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import Titlebar from "common/components/Titlebar";
import "common/assets/css/main-page.css";
import SuccessStories from "containers/Home/SuccessStories";
import GalleryPageSlider from "containers/Home/GalleryPageSlider";
import Seo from "components/seo";

const Gallery = () => {
  const seoData = {
    title: "Hair Wigs & Extensions Gallery | Radiance Hair Studio",
    description: "Browse the stunning gallery of Radiance showcasing our expert hair services, including customized wigs, hair transformations, and more. Explore our work today!",
    keywords: ["Studio Gallery"],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/before-after/11.webp",
      "https://www.radiancehairstudio.com/before-after/12.webp",
      "https://www.radiancehairstudio.com/before-after/13.webp",
      "https://www.radiancehairstudio.com/home/home-image-18.webp"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "disambiguatingDescription": "Studio Gallery",
    "additionalType": "https://www.radiancehairstudio.com/gallery.php",
    "category": "https://www.radiancehairstudio.com/gallery.php",
    "description": "Browse the stunning gallery of Radiance showcasing our expert hair services, including customized wigs, hair transformations, and more. Explore our work today!"
  };    

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <Titlebar title="Gallery" />
          <SuccessStories />
          <GalleryPageSlider />
          
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default Gallery;
